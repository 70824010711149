.gallery {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   padding: 0;
	gap: 1rem;
	margin-bottom: 6rem;
	text-align: center;
}

.gallery > div > img {
	aspect-ratio: 1;
	object-fit: cover;
	border-radius: .3rem;
}

.gallery > div > img:hover {
   -webkit-filter: brightness(.5);
   filter: brightness(.5);
   transition: var(--cubic-bez-4ms);
 }

@media (max-width: 72em) {
   .gallery {
      grid-template-columns: 1fr;
   }
}